
import MoneyAmount, {createMoneyAmount} from "../../scripts-core/model/MoneyAmount"
import { DateTime, fromDate } from "../../scripts-core/model/PhoenixDate"
import { ParseOp } from "../PhoenixTypes";

export type PathType = "collection" | "document"

function lastPathSegment(path : string):string {
    const index = path.lastIndexOf("/") + 1;
    return path.substring(index, path.length);
}

export function checkDBPath(path : string):PathType {
    const length = path.split("/").length;
    if(length % 2 == 0) return "document"
    return "collection"
}
/**
 * 
 * @param filename the full filename with extension
 * @returns the last segment after '.' representing extension
 */
export function checkExtension(filename : string): string {
    const split = filename.split(".");
    if(split.length == 1)
        return ""
    return `.${split[split.length-1]}`;
}

export function checkFailed(...operations : ParseOp<any>[]):boolean{
    for(let i=0; i < operations.length; i++){
        if(operations[i].success === false)
            return true;
    }
    return false;
}

export function parseMoney(amount : string): ParseOp<MoneyAmount> {
    amount = amount.trim().replaceAll(",", "");
    try{
        const parseAmount = createMoneyAmount(amount);
        return {
            success : true,
            result : parseAmount
        }
    }
    catch {
        return{
            success : false,
            result : createMoneyAmount("0")
        }
    }
}

function parseDate(date : string): ParseOp<DateTime> {
    try{
        const parsedDate = new Date(Date.parse(date));
        const dateTime = fromDate(parsedDate);
        return {
            result : dateTime,
            success: true
        }
    }
    catch {
        return{
            success : false,
            result : fromDate(new Date())
        }
    }
}