
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  },
  emits : ["search"]
})
export default class MeSearch extends Vue {
    @Prop()
    hint! : string

    searchText = ""

    onSearch(){
        this.$emit("search", this.searchText);
    }
}
