<template>
    <div class='mt-2 mb-2 flex'>
        <div class='mt-auto mb-auto material-icons'>search</div>
        <input v-model="searchText" @input="onSearch" class='w-full ml-2 mesearch-in' type="text" :placeholder="hint">
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  components : {
  },
  emits : ["search"]
})
export default class MeSearch extends Vue {
    @Prop()
    hint! : string

    searchText = ""

    onSearch(){
        this.$emit("search", this.searchText);
    }
}
</script>

<style scoped>
.mesearch-in {
    
}
</style>